import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
import {
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import Container from "@material-ui/core/Container";
import { useStyles } from "../assets/estilos/pages/fail-age";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBeer } from "@fortawesome/free-solid-svg-icons";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#eebf2f",
      main: "#eebf2f",
      dark: "#eebf2f",
      contrastText: "#1f3a4e",
    },
    secondary: {
      light: "#1f3a4e",
      main: "#1f3a4e",
      dark: "#1f3a4e",
      contrastText: "#fff",
    },
  },
});

function Copyright() {
  return (
    <Typography variant="body2">
      &copy; 2025 - Gelada em Casa <br /> We Love Gelada{" "}
      <FontAwesomeIcon icon={faBeer} />
    </Typography>
  );
}

class FailAge extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Avatar className={classes.avatar}>
            <img
              style={{ width: "100%" }}
              src="https://geladaemcasaapp.com.br/images/logo-primary.png"
            />
          </Avatar>

          <Container component="main" className={classes.main} maxWidth="md">
            <Typography
              component="h1"
              variant="h5"
              align="center"
              className={classes.title}
              paragraph
            >
              Bah!
            </Typography>

            <Typography variant="body1" align="center" paragraph>
              Tu não tens 18 anos, então vais ter que esperar para acessar todas
              as vantagens do Gelada em Casa APP.
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              Aproveita e compartilha o Gelada em Casa APP com tua família e
              teus amigos.
            </Typography>

            <Typography variant="caption" align="center" paragraph>
              {" "}
              É proibida a venda e distribuição de bebidas alcoólicas para
              menores de 18 anos.
            </Typography>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              className={classes.main}
            >
              <Grid item xs={3} sm={3} align="center">
                <Link
                  className="iconSocials"
                  href="http://www.facebook.com/share.php?u=https://geladaemcasaapp.com.br/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebook} size="lg" />
                </Link>
              </Grid>
              <Grid item xs={3} sm={3} align="center">
                <Link
                  className="iconSocials"
                  href="https://api.whatsapp.com/send?text=A%20melhor%20cerveja%20é%20a%20Gelada%20em%20Casa%20.%20Cadastre-se!%20%0D%0Ahttps://geladaemcasaapp.com.br/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                </Link>
              </Grid>
              <Grid item xs={3} sm={3} align="center">
                <Link
                  className="iconSocials"
                  href="http://twitter.com/share?text=A%20melhor%20cerveja%20é%20a%20Gelada%20em%20Casa%20.%20Cadastre-se!%20%0D%0A&url=https://geladaemcasaapp.com.br/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faTwitter} size="lg" />
                </Link>
              </Grid>
              <Grid item xs={3} sm={3} align="center">
                <Link
                  className="iconSocials"
                  href="mailto:?subject=Conheça o Gelada em Casa APP!&body==A%20melhor%20cerveja%20é%20a%20Gelada%20em%20Casa%20.%20Cadastre-se!%20%0D%0Ahttps://geladaemcasaapp.com.br/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faEnvelope} size="lg" />
                </Link>
              </Grid>
            </Grid>
          </Container>

          <footer className={classes.footer}>
            <Container maxWidth="lg">
              <Copyright />
            </Container>
          </footer>
        </ThemeProvider>
      </div>
    );
  }
}

FailAge.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(FailAge);
