import React from "react";
import { Link, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "../assets/estilos/pages/home";
import {
  Container,
  Typography,
  Button,
  Grid,
  Breadcrumbs,
  ButtonGroup,
  TextField,
  Snackbar,
} from "@material-ui/core";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import PropTypes from "prop-types";
import AppMenu from "../components/Menu";
import AppMenuLogged from "../components/MenuUser";
import Footer from "../components/Footer";
import Services from "../Services";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import MuiAlert from "@material-ui/lab/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserView, MobileView } from "react-device-detect";
import { If } from "react-if";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#eebf2f",
      main: "#eebf2f",
      dark: "#eebf2f",
      contrastText: "#1f3a4e",
    },
    secondary: {
      light: "#1f3a4e",
      main: "#1f3a4e",
      dark: "#1f3a4e",
      contrastText: "#fff",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ProductInside extends React.Component {
  state = {
    quantidade: 1,
    produto: [],
    produtos: [],
    logged: false,
    redirect: "",
    comprarAgora: false,
    openmodal: false,
  };

  componentDidMount() {
    document.getElementById("top").scroll(0, 0);
    let usuario = JSON.parse(localStorage.getItem("user")) || "";
    let carrinho = localStorage.getItem("carrinho") || "";
    let token = JSON.parse(localStorage.getItem("token")) || "";
    var params = this.props.match.params;

    if (usuario !== "" && token !== "") {
      this.setState({ logged: true });
    }

    Services.produto(this, parseInt(params.id));

    const values = [
      {
        id_marca: "",
        id_categoria: "",
        busca: "",
        pagina: 1,
        filtro: "destaque",
        valor_ini: "",
        valor_fim: "",
      },
    ];

    Services.produtos(this, values[0]);

    if (carrinho === "") {
      const cart = [
        {
          id_carrinho: "",
          id_produto: "",
          qntd: "",
          action: "criar",
        },
      ];
      Services.carrinho(this, usuario.id, token, cart[0]);
    }
  }

  onChangeQuantidade = (e) => {
    this.setState({ quantidade: e.target.value });
  };

  plus1 = () => {
    this.setState({ quantidade: parseInt(this.state.quantidade) + 1 });
  };

  plus6 = () => {
    this.setState({ quantidade: parseInt(this.state.quantidade) + 6 });
  };

  plus12 = () => {
    this.setState({ quantidade: parseInt(this.state.quantidade) + 12 });
  };

  remove = () => {
    if (this.state.quantidade > 1) {
      this.setState({ quantidade: parseInt(this.state.quantidade) - 1 });
    }
  };

  responseProduto(response) {
    if (response.success === "true") {
      this.setState({ produto: response });
    } else {
      this.setState({ produto: [] });
    }
  }

  responseProdutos(response) {
    if (response.success === "true") {
      this.setState({ produtos: response.produtos });
    } else {
      return;
    }
  }

  addCarrinho = (produto) => {
    let usuario = JSON.parse(localStorage.getItem("user")) || "";
    let carrinho = localStorage.getItem("carrinho") || "";
    let token = JSON.parse(localStorage.getItem("token")) || "";
    this.setState({ action: "add" });

    const cart = [
      {
        id_carrinho: carrinho,
        id_produto: produto,
        qntd: 1,
        action: "add_produto",
      },
    ];

    Services.carrinho(this, usuario.id, token, cart[0]);
  };

  addCarrinho2 = (produto) => {
    let usuario = JSON.parse(localStorage.getItem("user")) || "";
    let carrinho = localStorage.getItem("carrinho") || "";
    let token = JSON.parse(localStorage.getItem("token")) || "";
    this.setState({ action: "add" });

    const cart = [
      {
        id_carrinho: carrinho,
        id_produto: produto,
        qntd: this.state.quantidade,
        action: "add_produto",
      },
    ];

    Services.carrinho(this, usuario.id, token, cart[0]);
  };

  comprarAgora = (produto) => {
    let usuario = JSON.parse(localStorage.getItem("user")) || "";
    let carrinho = localStorage.getItem("carrinho") || "";
    let token = JSON.parse(localStorage.getItem("token")) || "";
    localStorage.setItem("carrinhoList", "ok");
    this.setState({ action: "add" });
    this.setState({ comprarAgora: true });

    const cart = [
      {
        id_carrinho: carrinho,
        id_produto: produto,
        qntd: this.state.quantidade,
        action: "add_produto",
      },
    ];

    Services.carrinho(this, usuario.id, token, cart[0]);
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
    this.setState({ alertMessage: "" });
    this.setState({ alertStatus: "" });

    if (this.state.comprarAgora === true && this.state.logged === true) {
      this.setState({ redirect: "checkout" });
      return;
    }

    if (this.state.comprarAgora === true && this.state.logged === false) {
      this.setState({ openmodal: true });
      return;
    }

    if (this.state.action === "add") {
      this.setState({ redirect: "sacola" });
      return;
    }

    window.location.reload();
  };

  responseCarrinho(response) {
    if (response.success === "true") {
      if (this.state.action === "criar") {
        localStorage.removeItem("carrinho");
        localStorage.setItem("carrinho", response.id_carrinho);
        return;
      }
      if (this.state.action === "add") {
        this.setState({ openAlert: true });
        this.setState({ alertMessage: "O produto foi adicionado ao pedido!" });
        this.setState({ alertStatus: "success" });
        return;
      }
    }
    if (response.error === "true" && response.type === "token_invalido") {
      localStorage.setItem("token_invalido", "ok");
      this.setState({ redirect: "#" });
      return;
    }
    if (response.error === "true" && response.type !== "token_invalido") {
      this.setState({ openAlert: true });
      this.setState({ alertMessage: "Opa!" + response.msg });
      this.setState({ alertStatus: "error" });
      return;
    }
  }

  handleClose = () => {
    this.setState({ openmodal: false });
  };

  redirectDetails = (produto) => {
    window.location.reload();
    // document.getElementById("top").scroll(0, 0)
  };

  render() {
    const { classes } = this.props;
    const produto = this.state.produto;

    if (this.state.redirect) {
      return <Redirect to={"/" + this.state.redirect} />;
    }
    return (
      <div className={classes.rootLightGray}>
        <ThemeProvider theme={theme}>
          <If condition={this.state.logged === false}>
            <AppMenu />
          </If>
          <If condition={this.state.logged === true}>
            <AppMenuLogged />
          </If>
          <main>
            <div className={classes.topSpace} />
            <Container maxWidth="lg" className={classes.container}>
              <Link to={"/produtos"}>
                <Button
                  color="secondary"
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                >
                  Voltar
                </Button>
              </Link>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/home">Home</Link>
                <Link to="/produtos">Produtos</Link>
                <Link to={"/produto/" + produto.id} aria-current="page">
                  {produto.titulo}
                </Link>
              </Breadcrumbs>
            </Container>
            <Container maxWidth="lg" className={classes.containerRounded}>
              <Grid
                className={classes.gridProducts}
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} lg={6} className="gridProductsInside">
                  <img src={produto.foto_pequena} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography
                    gutterBottom
                    className="productTitle"
                    variant="h4"
                    component="h1"
                    paragraph
                  >
                    {produto.titulo}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className="productDescription"
                    paragraph
                  >
                    {produto.descricao}
                  </Typography>
                  <If
                    condition={
                      produto.status_promo === 1 || produto.status_promo === "1"
                    }
                  >
                    <Typography
                      className="productPrice"
                      variant="h4"
                      component="h6"
                      paragraph
                    >
                      <span className="oldPrice">{produto.preco}</span>
                      {produto.preco_promo}
                    </Typography>
                  </If>
                  <If
                    condition={
                      produto.status_promo === 0 || produto.status_promo === "0"
                    }
                  >
                    <Typography
                      className="productPrice"
                      variant="h4"
                      component="h6"
                      paragraph
                    >
                      {produto.preco}
                    </Typography>
                  </If>

                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} align="center">
                      <ButtonGroup
                        color="secondary"
                        aria-label="contained secondary button group"
                      >
                        <Button onClick={this.remove}>{<Remove />}</Button>
                        <Button>
                          <TextField
                            className="textFieldProducts"
                            value={this.state.quantidade}
                            onChange={this.onChangeQuantidade}
                          />
                        </Button>
                        <Button onClick={this.plus1}>{<Add />}</Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6} lg={3}>
                      <Button
                        onClick={this.plus6}
                        color="primary"
                        size="large"
                        startIcon={<Add />}
                        fullWidth
                        variant="contained"
                      >
                        6
                      </Button>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Button
                        onClick={this.plus12}
                        color="primary"
                        size="large"
                        startIcon={<Add />}
                        fullWidth
                        variant="contained"
                      >
                        12
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Button
                        color="secondary"
                        size="large"
                        fullWidth
                        startIcon={<FontAwesomeIcon icon={faShoppingBag} />}
                        variant="contained"
                        onClick={() => this.addCarrinho2(produto.id)}
                      >
                        Adicionar ao pedido
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Button
                        color="secondary"
                        size="large"
                        fullWidth
                        startIcon={<FontAwesomeIcon icon={faCreditCard} />}
                        variant="contained"
                        onClick={() => this.comprarAgora(produto.id)}
                      >
                        Comprar agora
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Grid className={classes.gridProducts} container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    className={classes.title}
                    paragraph
                  >
                    Sugestões do Gelada
                  </Typography>
                </Grid>
                {this.state.produtos.map((produtos) => {
                  return (
                    <Grid item xs={6} lg={3} key={produtos.id}>
                      <Card raised={true}>
                        <Link to={"/produto-sugerido/" + produtos.id}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="auto"
                              image={produtos.foto_pequena}
                            />
                            <CardContent>
                              <BrowserView>
                                <Typography
                                  gutterBottom
                                  className="productTitle"
                                  variant="h5"
                                  component="h2"
                                  noWrap
                                >
                                  {produtos.titulo}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  className="productDescription"
                                  component="p"
                                >
                                  {produtos.marca} - {produtos.categoria}
                                </Typography>

                                <If
                                  condition={
                                    produtos.status_promo === 1 ||
                                    produtos.status_promo === "1"
                                  }
                                >
                                  <Typography
                                    className="productPrice"
                                    variant="h6"
                                  >
                                    <span className="oldPrice">
                                      {produtos.preco}
                                    </span>
                                    R$ {produtos.preco_promo}
                                  </Typography>
                                </If>
                                <If
                                  condition={
                                    produtos.status_promo === 0 ||
                                    produtos.status_promo === "0"
                                  }
                                >
                                  <Typography
                                    className="productPrice"
                                    variant="h6"
                                  >
                                    R$ {produtos.preco}
                                  </Typography>
                                </If>
                              </BrowserView>
                              <MobileView>
                                <Typography
                                  gutterBottom
                                  className="productTitle"
                                  variant="subtitle1"
                                  color="secondary"
                                  component="h3"
                                  noWrap
                                >
                                  {produtos.titulo}
                                </Typography>
                                <If
                                  condition={
                                    produtos.status_promo === 1 ||
                                    produtos.status_promo === "1"
                                  }
                                >
                                  <Typography
                                    className="productPrice"
                                    variant="subtitle1"
                                    color="secondary"
                                  >
                                    <span className="oldPrice">
                                      R$ {produtos.preco}
                                    </span>
                                    R$ {produtos.preco_promo}
                                  </Typography>
                                </If>
                                <If
                                  condition={
                                    produtos.status_promo === 0 ||
                                    produtos.status_promo === "0"
                                  }
                                >
                                  <Typography
                                    className="productPrice"
                                    variant="subtitle1"
                                    color="secondary"
                                  >
                                    R$ {produtos.preco}
                                  </Typography>
                                </If>
                              </MobileView>
                            </CardContent>
                          </CardActionArea>
                        </Link>
                        <CardActions>
                          <Grid spacing={1} container>
                            <Grid item xs={12} lg={5}>
                              <Button
                                className={classes.buttonProduct1}
                                color="secondary"
                                variant="contained"
                                onClick={() =>
                                  this.redirectDetails(produtos.id)
                                }
                              >
                                <Link to={"/produto/" + produtos.id}>
                                  Ver mais
                                </Link>
                              </Button>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Button
                                className={classes.buttonProduct1}
                                color="primary"
                                variant="contained"
                                onClick={() => this.addCarrinho(produtos.id)}
                              >
                                Add ao pedido
                              </Button>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Container>

            <Container maxWidth="lg">
              <Snackbar
                open={this.state.openAlert}
                autoHideDuration={5000}
                onClose={this.handleCloseAlert}
              >
                <Alert
                  severity={this.state.alertStatus}
                  onClose={this.handleCloseAlert}
                >
                  {this.state.alertMessage}
                </Alert>
              </Snackbar>
            </Container>
          </main>

          <Dialog
            open={this.state.openmodal}
            keepMounted
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={this.handleClose}
          >
            <DialogTitle id="alert-dialog-title">
              <Typography
                component="h1"
                variant="h5"
                align="center"
                className={classes.title}
                paragraph
              >
                Bah tchê! Tu precisas estar identificado para continuar:
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Button color="primary" variant="contained" fullWidth>
                    <Link to="/login">
                      {" "}
                      Sou cadastrado e quero fazer login{" "}
                    </Link>
                  </Button>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Button color="primary" variant="contained" fullWidth>
                    <Link to="/cadastro"> Ainda não sou cadastrado </Link>
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          <BrowserView>
            <Footer />
          </BrowserView>
        </ThemeProvider>
      </div>
    );
  }
}

ProductInside.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(ProductInside);
