import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Container,
  Grid,
  IconButton,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

export default function Footer() {
  function scrollToTop() {
    document.getElementById("top").scroll(0, 0);
  }
  return (
    <footer className="footer">
      <Button
        onClick={scrollToTop}
        className="buttonFooter"
        size="large"
        color="secondary"
        variant="contained"
      >
        Voltar ao Topo
      </Button>
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={4}>
          <Grid
            item
            container
            justify="space-between"
            alignItems="flex-start"
            direction="row"
            xs={12}
            lg={12}
            spacing={2}
          >
            <Grid item xs={12} lg={2}>
              <img
                src="https://geladaemcasaapp.com.br/images/logo-primary.png"
                className="w-100"
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <Typography component="h1" variant="h5" className="titleFooter">
                Acesso Rápido
              </Typography>

              <Link to="/produtos">Produtos</Link>
              <br />
              <Link to="/promocoes">Promoções</Link>
              <br />
              <Link to="/login">Entrar</Link>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Typography component="h1" variant="h5" className="titleFooter">
                Ficou com dúvida?
              </Typography>

              <Link to="/faq">FAQ</Link>
              <br />
              <Link to="/ajuda">Ajuda</Link>
              <br />
              <Link to="/termosdeuso">Termos de Uso</Link>
            </Grid>

            <Grid item xs={12} lg={2}>
              <Typography component="h1" variant="h5" className="titleFooter">
                Baixe o app
              </Typography>

              <a
                href="https://play.google.com/store/apps/details?id=com.app.geladaemcasa"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className="w-100"
                  src="https://geladaemcasaapp.com.br/images/google-play.png"
                />
              </a>
              <br />
              <a
                href="https://apps.apple.com/us/app/gelada-em-casa-app/id1541225001"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className="imgMockup"
                  src="https://geladaemcasaapp.com.br/images/app-store.png"
                />
              </a>
              <br />
            </Grid>

            <Grid item xs={12} lg={2}>
              <Typography component="h1" variant="h5" className="titleFooter">
                Siga-nos!
              </Typography>
              <IconButton
                href="https://www.facebook.com/geladaemcasaapp"
                aria-label="Facebook"
                className="iconFooter"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/geladaemcasaapp/"
                aria-label="Instagram"
                className="iconFooter"
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                href="https://br.linkedin.com/in/gelada-em-casa-app-10aa211b3"
                aria-label="LinkedIn"
                className="iconFooter"
              >
                <LinkedInIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            align="center"
          >
            <Grid item xs={12} lg={12}>
              <Typography variant="body2">
                &copy; 2020 Gelada em Casa - Inscrito sob CNPJ nº
                37.442.532/0001-99
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
