import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Roboto Condensed",
  },
  appBar: {
    color: "#1f3a4e",
    backgroundColor: "#eebf2f",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  toolbar: {
    minHeight: theme.spacing(11),
  },
  logo: {
    width: theme.spacing(9),
    marginTop: theme.spacing(1),
  },
  title: {
    alignItems: "center",
    flexGrow: 1,
    textTransform: "uppercase",
    color: "#1f3a4e",
    marginLeft: "15px",
    fontFamily: "Bebas Neue, cursive !important",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#F9F9F9",
    borderLeft: "none !important",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    textTransform: "uppercase",
    color: "#1f3a4e",
    fontSize: 18,
    fontFamily: "Bebas Neue, cursive !important",
    backgroundColor: "#F9F9F9 !important",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  listMenu: {
    color: "#1f3a4e !important",
    fontSize: 16,
  },
  minHeight: {
    height: theme.spacing(11),
    display: "flex",
    alignItems: "center",
  },
  exclusionAccountModal: {
    textAlign: "center",
    color: "#eebf2f",
  },
  exclusionAccountModalTitle: {
    textTransform: "uppercase",
    color: "#1f3a4e",
    fontFamily: "Bebas Neue, cursive !important",
  },
  exclusionAccountModalText: {
    color: "#1f3a4e",
    fontFamily: "Roboto Condensed,sans-serif",
  },
  exclusionAccountModalButton: {
    width: "50%",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFFFFF",
    fontFamily: "Bebas Neue, cursive !important",
    backgroundColor: "#FF0000",
    "&:hover": {
      color: "#FF0000",
    },
  },
  exclusionAccountModalButtonDismiss: {
    width: "100%",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#1f3a4e",
    fontFamily: "Bebas Neue, cursive !important",
    backgroundColor: "#eebf2f",
  },
  exclusionAccountModalLink: {
    padding: "auto",
    width: "50%",
  },
}));

export default useStyles;
