import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faPercentage,
  faQuestion,
  faInfo,
  faHome,
  faFileAlt,
  faMapMarked,
  faBullhorn,
  faSignOutAlt,
  faNewspaper,
  faDoorClosed,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const MainListItems = ({ excludeAccountOnClick = () => undefined }) => {
  return (
    <div>
      <Link to={"/home"}>
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon
              style={{ color: "#1f3a4e" }}
              icon={faHome}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </Link>
      <Link to={"/pedidos"}>
        <ListItem button>
          <ListItemIcon>
            <FontAwesomeIcon
              style={{ color: "#1f3a4e" }}
              icon={faFileAlt}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Meus pedidos" />
        </ListItem>
      </Link>
      <Link to={"/enderecos"}>
        <ListItem button>
          <ListItemIcon>
            <FontAwesomeIcon
              style={{ color: "#1f3a4e" }}
              icon={faMapMarked}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Meus endereços" />
        </ListItem>
      </Link>
      <Link to={"/produtos"}>
        <ListItem button>
          <ListItemIcon>
            <FontAwesomeIcon
              style={{ color: "#1f3a4e" }}
              icon={faBeer}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Produtos" />
        </ListItem>
      </Link>
      <Link to={"/promocoes"}>
        <ListItem button>
          <ListItemIcon>
            <FontAwesomeIcon
              style={{ color: "#1f3a4e" }}
              icon={faPercentage}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Promoções" />
        </ListItem>
      </Link>
      <Link to={"/chamados"}>
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon
              style={{ color: "#1f3a4e" }}
              icon={faBullhorn}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Chamados" />
        </ListItem>
      </Link>
      <Link to={"/faq"}>
        <ListItem button>
          <ListItemIcon>
            <FontAwesomeIcon
              style={{ color: "#1f3a4e" }}
              icon={faQuestion}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Faq" />
        </ListItem>
      </Link>
      <Link to={"/ajuda"}>
        <ListItem button>
          <ListItemIcon>
            <FontAwesomeIcon
              style={{ color: "#1f3a4e" }}
              icon={faInfo}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Ajuda" />
        </ListItem>
      </Link>
      <a
        href="https://geladaemcasaapp.com.br/blog/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon
              style={{ color: "#1f3a4e" }}
              icon={faNewspaper}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Blog do Gelada" />
        </ListItem>
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=5551992264910&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Gelada%20em%20Casa%20APP!"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faWhatsapp}
              style={{ color: "#1f3a4e" }}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Whats do Gelada" />
        </ListItem>
      </a>
      <Link
        to={"/"}
        onClick={() => {
          localStorage.clear();
          if (window.cordova) {
            window.plugins.OneSignal.logout();
          }
        }}
      >
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon
              style={{ color: "#1f3a4e" }}
              icon={faSignOutAlt}
              size="lg"
            />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </Link>
      <ListItem onClick={excludeAccountOnClick} style={{ cursor: "pointer" }}>
        <ListItemText
          primary="Excluir minha conta"
          style={{ color: "#FF0000" }}
        />
      </ListItem>
      <ListItem>
        <ListItemText primary="Versão 1.0.37" />
      </ListItem>
    </div>
  );
};

export default MainListItems;
