import React, { createRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "../assets/estilos/pages/home";
import {
  Container,
  Typography,
  Button,
  Slide,
  Grid,
  Snackbar,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import PropTypes from "prop-types";
import AppMenu from "../components/Menu";
import AppMenuLogged from "../components/MenuUser";
import Footer from "../components/Footer";
import Bairros from "../components/Bairros";
import Marcas from "../components/Brands";
import Filters from "../components/Filters";
import ReactTimeout from "react-timeout";
import AlertOpen from "../components/AlertOpen";
import Services from "../Services";
import MuiAlert from "@material-ui/lab/Alert";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import { BrowserView, MobileView } from "react-device-detect";
import { If } from "react-if";
import { ONESIGNAL_APP_ID } from "../environments";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#eebf2f",
      main: "#eebf2f",
      dark: "#eebf2f",
      contrastText: "#1f3a4e",
    },
    secondary: {
      light: "#1f3a4e",
      main: "#1f3a4e",
      dark: "#1f3a4e",
      contrastText: "#fff",
    },
  },
});

class Home extends React.Component {
  state = {
    nome: "",
    email: "",
    error: "",
    redirect: "",
    action: "criar",
    openmodal: false,
    logged: false,
    produtos: [],
    categorias: [],
    marcas: [],
    banners: [],
    funcionamento: [],
  };

  componentDidMount() {
    const position = navigator.geolocation.getCurrentPosition(this.onSuccess);
    document.getElementById("top").scroll(0, 0);

    let driver = localStorage.getItem("driver") || "";

    if (driver !== "") {
      this.setState({ redirect: "homeentregador" });
      return;
    }

    let confirm = window.localStorage.getItem("confirm") || "";
    let usuario = JSON.parse(localStorage.getItem("user")) || "";
    let carrinho = localStorage.getItem("carrinho") || "";
    let token = JSON.parse(localStorage.getItem("token")) || "";

    if (usuario !== "" && token !== "") {
      this.setState({ logged: true });
      if (window.cordova && usuario.status_onesignal === "2") {
        this.oneSignalPush();
      }
    }

    if (confirm === "") {
      this.setState({ openmodal: true });
    }

    const values = [
      {
        id_marca: "",
        id_categoria: "",
        busca: "",
        pagina: 1,
        filtro: "destaque",
        valor_ini: "",
        valor_fim: "",
      },
    ];

    Services.produtos(this, values[0]);

    if (carrinho === "") {
      const cart = [
        {
          id_carrinho: "",
          id_produto: "",
          qntd: "",
          action: "criar",
        },
      ];
      Services.carrinho(this, usuario.id, token, cart[0]);
    }

    Services.banners(this);
  }

  oneSignalPush = async () => {
    if (window.cordova) {
      let usuario = JSON.parse(localStorage.getItem("user")) || "";
      try {
        window.plugins.OneSignal.initialize(ONESIGNAL_APP_ID);

        window.plugins.OneSignal.Notifications.addEventListener(
          "click",
          this.notificationOpenedCallback
        );

        window.plugins.OneSignal.Notifications.requestPermission(true).then(
          (accepted) => {
            console.log("User accepted notifications: " + accepted);
          }
        );

        if (window.cordova.platformId === "android") {
          window.plugins.OneSignal.User.pushSubscription.optIn();
        }

        window.plugins.OneSignal.login(usuario.id);

        const listener = async () => {
          try {
            let onesignal = null;
            let attempts = 0;

            while (!onesignal && attempts < 10) {
              onesignal = await window.plugins.OneSignal.User.getOnesignalId();
              if (!onesignal) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
              }
              attempts++;
            }

            if (onesignal) {
              let token = JSON.parse(localStorage.getItem("token")) || "";
              Services.oneSignal(usuario.id, token, onesignal);

              window.plugins.OneSignal.User.removeEventListener(
                "change",
                listener
              );
            } else {
              console.warn("OneSignal ID continua nulo após as tentativas.");
            }
          } catch (error) {
            console.error("Erro ao capturar o OneSignal ID:", error);
          }
        };

        window.plugins.OneSignal.User.addEventListener("change", listener);
      } catch (error) {
        console.error("Erro ao inicializar o OneSignal:", error);
      }
    }
  };

  notificationOpenedCallback = (jsonData) => {
    console.log("notificationOpenedCallback: " + JSON.stringify(jsonData));
  };

  onSuccess = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    let usuario = JSON.parse(localStorage.getItem("user")) || "";
    let token = JSON.parse(localStorage.getItem("token")) || "";

    Services.geolocalizacao(this, usuario.id, token, latitude, longitude);
  };

  handleClose = () => {
    this.setState({ openmodal: false });
    window.localStorage.setItem("confirm", "1");
  };

  responseProdutos(response) {
    if (response.success === "true") {
      this.setState({ produtos: response.produtos });
    } else {
      return;
    }
  }

  addCarrinho = (produto) => {
    let usuario = JSON.parse(localStorage.getItem("user")) || "";
    let carrinho = localStorage.getItem("carrinho") || "";
    let token = JSON.parse(localStorage.getItem("token")) || "";
    this.setState({ action: "add" });

    const cart = [
      {
        id_carrinho: carrinho,
        id_produto: produto,
        qntd: 1,
        action: "add_produto",
      },
    ];

    Services.carrinho(this, usuario.id, token, cart[0]);
  };

  responseCarrinho(response) {
    if (response.success === "true") {
      if (this.state.action === "criar") {
        localStorage.removeItem("carrinho");
        localStorage.setItem("carrinho", response.id_carrinho);
        return;
      }
      if (this.state.action === "add") {
        this.setState({ openAlert: true });
        this.setState({ alertMessage: "O produto foi adicionado ao pedido!" });
        this.setState({ alertStatus: "success" });
        return;
      }
    }
    if (response.error === "true" && response.type === "token_invalido") {
      localStorage.setItem("token_invalido", "ok");
      this.setState({ redirect: "#" });
      return;
    }
    if (response.error === "true" && response.type !== "token_invalido") {
      this.setState({ openAlert: true });
      this.setState({ alertMessage: "Opa!" + response.msg });
      this.setState({ alertStatus: "error" });
      return;
    }
  }

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
    this.setState({ alertMessage: "" });
    this.setState({ alertStatus: "" });

    if (this.state.action === "add") {
      this.setState({ redirect: "sacola" });
      return;
    }

    window.location.reload();
  };

  responseBanners = (response) => {
    if (response.success === "true") {
      this.setState({ banners: response.banners });
    } else {
      this.setState({ openAlert: true });
      this.setState({ alertMessage: "Opa!" + response.msg });
      this.setState({ alertStatus: "error" });
      return;
    }
  };

  render() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const { classes } = this.props;
    if (this.state.redirect) {
      return <Redirect to={"/" + this.state.redirect} />;
    }
    return (
      <div className={classes.root} id="top">
        <ThemeProvider theme={theme}>
          <If condition={this.state.logged === false}>
            <AppMenu />
          </If>
          <If condition={this.state.logged === true}>
            <AppMenuLogged />
          </If>
          <main>
            <div className={classes.topSpace} />
            <BrowserView>
              <AutoplaySlider
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={6000}
                mobileTouch={false}
                organicArrows={false}
                bullets={false}
              >
                {this.state.banners.map((item, index) => {
                  return (
                    <div
                      key={index}
                      data-src={item.imagem}
                      className="caroussel"
                    />
                  );
                })}
              </AutoplaySlider>
            </BrowserView>

            <MobileView>
              <AutoplaySlider
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={6000}
                organicArrows={false}
                bullets={false}
              >
                {this.state.banners.map((item, index) => {
                  return (
                    <div
                      key={index}
                      data-src={item.imagem_mobile}
                      className="caroussel"
                    />
                  );
                })}
              </AutoplaySlider>
            </MobileView>

            <Container maxWidth="lg" className={classes.container}>
              <AlertOpen />
            </Container>

            <Container maxWidth="lg" className={classes.container}>
              {/* <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Typography component="h2" variant="h5" align="center" className={classes.title} paragraph>
                                    Categorias
                                </Typography>
                            </Grid>
                        </Grid> */}

              <Filters />
            </Container>

            <Container maxWidth="lg" className={classes.container}>
              <Grid className={classes.gridProducts} container spacing={2}>
                {/* <Grid item xs={12} lg={12}>
                                <Typography component="h1" variant="h5" align="center" className={classes.title} paragraph>
                                    Produtos
                            </Typography>
                            </Grid> */}
                {this.state.produtos.map((produtos) => {
                  return (
                    <Grid item xs={6} lg={3} key={produtos.id}>
                      <Card raised={true}>
                        <Link to={"/produto/" + produtos.id}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="auto"
                              image={produtos.foto_pequena}
                            />
                            <CardContent>
                              <BrowserView>
                                <Typography
                                  gutterBottom
                                  className="productTitle"
                                  variant="h5"
                                  component="h2"
                                  noWrap
                                >
                                  {produtos.titulo}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  className="productDescription"
                                  component="p"
                                >
                                  {produtos.marca} - {produtos.categoria}
                                </Typography>

                                <If
                                  condition={
                                    produtos.status_promo === 1 ||
                                    produtos.status_promo === "1"
                                  }
                                >
                                  <Typography
                                    className="productPrice"
                                    variant="h6"
                                  >
                                    <span className="oldPrice">
                                      {produtos.preco}
                                    </span>
                                    R$ {produtos.preco_promo}
                                  </Typography>
                                </If>
                                <If
                                  condition={
                                    produtos.status_promo === 0 ||
                                    produtos.status_promo === "0"
                                  }
                                >
                                  <Typography
                                    className="productPrice"
                                    variant="h6"
                                  >
                                    R$ {produtos.preco}
                                  </Typography>
                                </If>
                              </BrowserView>
                              <MobileView>
                                <Typography
                                  gutterBottom
                                  className="productTitle"
                                  variant="subtitle1"
                                  color="secondary"
                                  component="h3"
                                  noWrap
                                >
                                  {produtos.titulo}
                                </Typography>
                                <If
                                  condition={
                                    produtos.status_promo === 1 ||
                                    produtos.status_promo === "1"
                                  }
                                >
                                  <Typography
                                    className="productPrice"
                                    variant="subtitle1"
                                    color="secondary"
                                  >
                                    <span className="oldPrice">
                                      R$ {produtos.preco}
                                    </span>
                                    R$ {produtos.preco_promo}
                                  </Typography>
                                </If>
                                <If
                                  condition={
                                    produtos.status_promo === 0 ||
                                    produtos.status_promo === "0"
                                  }
                                >
                                  <Typography
                                    className="productPrice"
                                    variant="subtitle1"
                                    color="secondary"
                                  >
                                    R$ {produtos.preco}
                                  </Typography>
                                </If>
                              </MobileView>
                            </CardContent>
                          </CardActionArea>
                        </Link>
                        <CardActions>
                          <Grid spacing={1} container>
                            <Grid item xs={12} lg={5}>
                              <Button
                                className={classes.buttonProduct1}
                                color="secondary"
                                variant="contained"
                              >
                                <Link to={"/produto/" + produtos.id}>
                                  Ver mais
                                </Link>
                              </Button>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Button
                                className={classes.buttonProduct1}
                                color="primary"
                                variant="contained"
                                onClick={() => this.addCarrinho(produtos.id)}
                              >
                                Add ao pedido
                              </Button>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Container>

            <Container maxWidth="lg" className={classes.container}>
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    className={classes.title}
                    paragraph
                  >
                    Marcas
                  </Typography>
                </Grid>
              </Grid>

              <Marcas />
            </Container>

            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    className={classes.title}
                    paragraph
                  >
                    Bairros Atendidos
                  </Typography>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="secondary"
                    paragraph
                  >
                    Olá, atualmente estamos entregando a tua gelada nos bairros
                    de Porto Alegre listados abaixo: <br />
                    <Bairros />
                    Vai te preparando, em breve estaremos atendendo em diversos
                    outros bairros de nossa querida cidade.
                    <br />
                  </Typography>
                </Grid>
              </Grid>
            </Container>

            <If condition={window.cordova === undefined}>
              <Container maxWidth="lg" className={classes.container}>
                <Grid
                  container
                  spacing={2}
                  className="bg-light-gray"
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={12} lg={6}>
                    <img
                      className="imgMockup"
                      src="https://geladaemcasaapp.com.br/images/mockup.jpg"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography
                      component="h1"
                      variant="h5"
                      align="center"
                      className={classes.title}
                      paragraph
                    >
                      Tenha o gelada em casa <br /> na palma da sua mão!
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h6"
                      align="center"
                      color="primary"
                      paragraph
                    >
                      Ainda não baixou o app do Gelada em Casa tchê?!
                    </Typography>
                    <Typography
                      component="h3"
                      variant="subtitle1"
                      align="center"
                      color="secondary"
                      paragraph
                    >
                      Baixa agora e receba tua ceva gelada em casa, com
                      agilidade e eficiência! Afinal, a melhor cerveja é a
                      Gelada em Casa!
                    </Typography>

                    <Grid container spacing={2} className="bg-light-gray">
                      <Grid item xs={12} lg={3}></Grid>
                      <Grid item xs={6} lg={3}>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.app.geladaemcasa"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            className="imgMockup"
                            src="https://geladaemcasaapp.com.br/images/google-play.png"
                          />
                        </a>
                      </Grid>
                      <Grid item xs={6} lg={3}>
                        <a
                          href="https://apps.apple.com/us/app/gelada-em-casa-app/id1541225001"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            className="imgMockup"
                            src="https://geladaemcasaapp.com.br/images/app-store.png"
                          />
                        </a>
                      </Grid>
                      <Grid item xs={12} lg={3}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </If>
            <Container maxWidth="lg">
              <Snackbar
                open={this.state.openAlert}
                autoHideDuration={5000}
                onClose={this.handleCloseAlert}
              >
                <Alert
                  severity={this.state.alertStatus}
                  onClose={this.handleCloseAlert}
                >
                  {this.state.alertMessage}
                </Alert>
              </Snackbar>
            </Container>
          </main>

          <BrowserView>
            <Footer />
          </BrowserView>

          <Dialog
            open={this.state.openmodal}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.ageModal}
          >
            <DialogTitle
              id="alert-dialog-title"
              className={classes.ageModalTitle}
            >
              <img
                className={classes.ageModalAvatar}
                src="https://geladaemcasaapp.com.br/images/logo-primary.png"
              />
              <Typography component="h2" variant="h5" align="center">
                Confirmação de idade
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.ageModalText}
                id="alert-dialog-description"
              >
                Tchê! Para continuar, precisamos saber se tu és maior de 18
                anos.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.ageModalButton}
                onClick={this.handleClose}
              >
                Sou maior de 18 anos
              </Button>
              <div className={classes.ageModalLink}>
                <Link to="/menorde18anos">
                  <Button className={classes.ageModalButtonLink}>
                    Sou menor de 18 anos
                  </Button>
                </Link>
              </div>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default ReactTimeout(withStyles(useStyles)(Home));
