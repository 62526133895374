import React, { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import {
  Drawer,
  Badge,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Avatar,
  Button,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Typography,
  Dialog,
  Slide,
  Container,
  Snackbar,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingBag,
  faUser,
  faBars,
  faHome,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import useStyles from "../assets/estilos/pages/menu";
import Notification from "./Notification";
import CartInfo from "./Cart";
import MainListItems from "./MenuItensUser";
import Services from "../Services";
import Alert from "@material-ui/lab/Alert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AppMenu() {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("");

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalExclusionOpen, setModalExclusionOpen] = React.useState(false);
  const [modalExclusionLoading, setModalExclusionLoading] =
    React.useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const token = JSON.parse(localStorage.getItem("token"));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const accountExclusionConfirm = async () => {
    setModalExclusionLoading(true);
    try {
      const response = await Services.excluirUsuario(user.id, token);
      if (response.success) {
        if (window.cordova) {
          window.plugins.OneSignal.logout();
        }
        localStorage.clear();
        window.location.reload();
      } else {
        setOpenAlert(true);
        setAlertMessage(`Opa! ${response.msg}`);
        setAlertStatus("error");
      }
    } catch (error) {
      setOpenAlert(true);
      setAlertMessage("Opa! Ocorreu um erro ao excluir o usuário.");
      setAlertStatus("error");
    } finally {
      setModalExclusionLoading(false);
    }
  };

  const accountExclusionDismiss = () => {
    setModalExclusionOpen(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setAlertMessage("");
    setAlertStatus("");
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.title}>
              <Link to="/home">
                <img
                  className={classes.logo}
                  src="https://geladaemcasaapp.com.br/images/logo-primary.png"
                />
              </Link>
            </div>

            <Link to="/home">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                className={clsx(open && classes.hide)}
              >
                <FontAwesomeIcon icon={faHome} />
              </IconButton>
            </Link>

            <Link to="/notificacoes">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
              >
                <Badge badgeContent={<Notification />} color="secondary">
                  <FontAwesomeIcon icon={faBell} />
                </Badge>
              </IconButton>
            </Link>

            <Link to="/sacola">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
              >
                <Badge badgeContent={<CartInfo />} color="secondary">
                  <FontAwesomeIcon icon={faShoppingBag} />
                </Badge>
              </IconButton>
            </Link>

            {user?.avatar && (
              <Link to="/perfil">
                <Avatar className="ml-10">
                  <img className="w-100" src={user.avatar} />
                </Avatar>
              </Link>
            )}

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={clsx(open && classes.hide)}
            >
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
              {/* {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
            </IconButton>
            <div className={classes.minHeight}> Olá {user.primeiro_nome}!</div>
          </div>
          <Divider />
          <List className={classes.listMenu}>
            <MainListItems
              excludeAccountOnClick={() => setModalExclusionOpen(true)}
            />
          </List>
        </Drawer>

        <Dialog
          open={modalExclusionOpen}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.exclusionAccountModal}
        >
          <DialogTitle
            id="alert-dialog-title"
            className={classes.exclusionAccountModalTitle}
          >
            <Typography component="h2" variant="h5" align="center">
              Exclusão de conta
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              className={classes.exclusionAccountModalText}
              id="alert-dialog-description"
            >
              Você tem certeza que deseja excluir sua conta? Seus dados serão
              totalmente apagados e você não terá acesso a esta conta.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.exclusionAccountModalButton}
              onClick={accountExclusionConfirm}
              disabled={modalExclusionLoading}
            >
              Excluir minha conta
            </Button>
            <div className={classes.exclusionAccountModalLink}>
              <Button
                className={classes.exclusionAccountModalButtonDismiss}
                onClick={accountExclusionDismiss}
                disabled={modalExclusionLoading}
              >
                Cancelar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      <Container maxWidth="lg" className={classes.comingSoon}>
        <Snackbar
          open={openAlert}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
        >
          <Alert severity={alertStatus} onClose={handleCloseAlert}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}
